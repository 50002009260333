<template>
    <div>
        <div class="row">
            <div class="col-12">
                <label class="form-label float-left">Questão {{ length + index + 1 }} - </label>
                <i  @click="delQuestion()" class="fa fa-trash text-danger ml-2 float-left pointer mt-1"
                   title="Excluir Questão"></i>
            </div>
        </div>
        <QuestionsEdit
            :type="type"
            :question="question"
            :index="index"
            @cancel-form="form=false"
            @update-question="updateQuestion"
            @update-new-question="updateNewQuestion"
        />
        <div class="row">
            <div class="col-12">
                <hr>
            </div>
        </div>
    </div>
</template>
<script>

import QuestionsEdit from "./questions-edit.vue";

export default {
    props: {
        index: {
            required: true,
            type: Number
        },
        question: {
            required: true,
            type: Object
        },
        length: {
            required: true,
            type: Number
        },
        type: {
            required: true,
            type: String
        }
    },
    components: {
        QuestionsEdit
    },
    data() {
        return {
            show: false,
            form: false,
        }
    },
    methods: {
        updateQuestion(payload) {
            this.question = payload;
            this.form = false;
        },

        updateNewQuestion(payload) {
            this.question = payload.data;
            this.form = false;
            // Atualizando array no componente pai
            this.$emit('update-new-question', payload);
        },

        async delQuestion() {
            this.$emit('delete-question', this.index);
        }
    },
}
</script>
