<template>
    <div>
        <div class="row">
            <div class="col-12 mb-3">
                <textarea class="textareaSize form-control" aria-required="true" v-model="question.question" required> </textarea>
            </div>
            <div class="col-md-4 mb-3">
                <label class="form-label">Tipo <span class="text-danger">*</span></label>
                <select name="category" v-model="question.type" class="form-control">
                    <option value="">Selecione</option>
                    <option value="1">Dissertativa</option>
                    <option value="2">Única Escolha</option>
                    <option value="3">Multipla escolha</option>
                </select>
            </div>
            <div class="col-md-12 mb-3" v-if="question.type == '2' || question.type == '3'">
                <label class="form-label">Alternativas <span class="text-danger">*</span></label>
                <input
                    class="form-control"
                    v-model="currentAlternative"
                    @keyup.enter="addAlternative"
                    @keydown.tab.prevent="addAlternative"
                    placeholder="Digite a alternativa e precione Enter..."
                />

                <div class="tag mt-2" v-for="(alternative, index) in question.alternatives" :key="index">
                    <span v-if="typeof alternative === 'object' ">{{ alternative.alternative }}</span>
                    <span v-else>{{ alternative }}</span>
                    <span class="remove-tag" @click="removeAlternative(index)">×</span>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import http from "@/http";
import {toFormData} from "@/components/composables/cruds";
import {messageSwal} from "@/components/composables/messages";

export default {
    props: {
        question: {
            required: true,
            type: Object
        },
        index: {
            required: true,
            type: Number
        },
        type: {
            required: true,
            type: String
        }
    },

    components: {
    },

    data() {
        return {
            currentAlternative: '',
        }
    },

    methods: {
        // salvar questão enviando para componente pai
        async save() {

            let result = true;
            if (this.currentAlternative.trim() !== '') {
                result = await messageSwal(
                    "Atenção!",
                    "Você não salvou todas as alternativas! Deseja mesmo continuar?",
                    "warning"
                )
            }

            if (result) {

                // Validando formulário
                if (this.question.question === '' || this.question.type === '' || (this.question.type > 1 && this.question.alternatives.length === 0)) {
                    this.$notifyError('Preencha todos os campos obrigatórios!');
                    return;
                }
                // Questão descritiva apaga as alternativas
                if (this.question.type == '1') this.question.alternatives = [];

                // Se for uma questão já salva no back end
                if (this.type === 'saved') {
                    let data = toFormData(this.question);

                    if (this.question.type != '1') {
                        this.question.alternatives.forEach(element => {
                            data.append('alternative[]', element.alternative);
                        })
                    }

                    data.append('_method', 'PUT');

                    http.post('form-questions/' + this.question.id, data)
                        .then((response) => {
                            console.log(response)
                            this.$notifySuccess('Questão alterada com sucesso!')
                            this.$emit('update-question', response.data);
                        })
                        .catch(e => {
                            console.error('Edit Question: ', e);
                            this.$notifyError(e);
                        })
                } else if (this.type === 'new') {
                    const now = new Date();
                    this.$emit('update-new-question', {
                        index: this.index,
                        data: {id: now.getTime(), ...this.question}
                    });
                }
            }
        },

        cancel() {
            this.$emit('cancel-form');
        },

        // Adicionar novas alternativas
        addAlternative() {
            const now = new Date();
            if (this.currentAlternative.trim() !== '' && !this.question.alternatives.includes(this.currentAlternative)) {
                this.question.alternatives.push({
                        id: now.getTime(),
                        alternative: this.currentAlternative.trim()
                    }
                );
                this.currentAlternative = '';
            }
        },

        // Removendo alternativa
        removeAlternative(index) {
            this.question.alternatives.splice(index, 1);
        },
    },

}
</script>


<style scoped>
.textareaSize {
    width:100%;
    height:80px;
}

.alternatives-input-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
}

.tag {
    background-color: #efefef;
    margin: 2px;
    padding: 5px 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.remove-tag {
    margin-left: auto;
    cursor: pointer;
}
</style>
