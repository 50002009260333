<template>
    <Layout>

        <div class="row">
            <div class="col-12">

                <div class="page-separator">
                    <div class="page-separator__text">Informações Básicas</div>
                </div>

                <div class="card">
                    <div class="card-body p-4">
                        <div class="row">

                            <div class="col-12 mb-3">
                                <label class="form-label">Título do Formulário <span
                                    class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="form.title"
                                       placeholder="Título do Formulário" required>
                            </div>

                            <div class="col-12 mb-3">
                                <label class="form-label">Assunto/Descrição <span class="text-danger">*</span></label>
                                <vue-editor ref="editor1" class="editorSize" aria-required="true" v-model="form.subject"
                                            required :editor-toolbar="customToolbar"/>
                            </div>
                            <div class="col-12 mb-3 mt-4">
                                <label class="form-label">Questões:</label>
                                <hr class="m-0">
                            </div>
                        </div>
                        <div v-if="form.questions.length > 0 || newQuestions.length > 0">
                            <div v-for="(question, index) in form.questions" :key="'question-div-' + question.id">
                                <QuestionsView
                                    :index="index"
                                    :length="0"
                                    :question="question"
                                    type="saved"
                                    @delete-question="deleteQuestion"
                                />
                            </div>
                        </div>
                        <div v-else>
                            <div class="card-title">
                                Nenhuma questão cadastrada
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 mt-3">
                                <button type="button" class="btn btn-outline-primary mb-24pt mb-sm-0"
                                        @click="addQuestion()">
                                    Adicionar Questão
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button type="button" @click.prevent="$router.push('/formularios')"
                                        class="btn btn-primary btn-save" style="right:150px;">
                                    Voltar
                                </button>
                            </div>
                            <div class="col">
                                <button type="button" @click.prevent="save" id="save" class="btn btn-primary btn-save">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>

</template>

<script>
import Layout from '@/components/layout/main.vue';
import QuestionsView from "./questions-view.vue";
import {VueEditor} from "vue2-editor";
import {endLoading, startLoading} from "@/components/composables/spinners";
import {toFormData, ValidateForm} from "@/components/composables/cruds";
import http from "@/http";

export default {
    components: {
        Layout,
        QuestionsView,
        VueEditor,
    },
    data() {
        return {
            showSpinner: false,
            customToolbar: [
                [{'size': ['small', false, 'large', 'huge']}],
                ["bold", "italic"],
                [{list: "ordered"}, {list: "bullet"}],
            ],
            form: {
                id: 0,
                title: '',
                subject: '',
                questions: [],
                mentorings: [],
            },
            newQuestions: [],
            formId: '',
        }
    },
    methods: {

        initializeEditors() {
            const editorRefs = ['editor1', 'editor2', 'editor3'];
            editorRefs.forEach(ref => {
                const editor = this.$refs[ref].quill;
                editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
                    delta.ops.forEach(op => {
                        if (op.attributes) {
                            if ('color' in op.attributes) {
                                delete op.attributes.color;
                            }
                            if ('background' in op.attributes) {
                                delete op.attributes.background;
                            }
                        }
                    });
                    return delta;
                });
            });
        },

        // Dando update nas questões
        updateData(payload) {
            this.form.questions.push(payload);
        },

        //Adicionando uma nova questão
        addQuestion() {
            this.form.questions.push({
                "id": '',
                "question": "",
                "type": "",
                "alternatives": [],
                "mentorings": []
            });
        },

        deleteQuestion(index) {
            this.form.questions.splice(index, 1);
        },

        // Excluir nova questão
        deleteNewQuestion(index) {
            this.newQuestions.splice(index, 1);
        },

        save() {
            this.showSpinner = true
            // Campos obrigatórios a serem preenchidos
            const required = {
                title: 'Título',
                subject: 'Assunto',
            };

            startLoading('form', 'save');

            // Verificando se existe campo obrigatórios em branco
            if (!ValidateForm(this.$root, this.form, required)) {
                endLoading('form', 'save');
                this.hideSpinner();
                return;
            }

            this.form.questions = this.form.questions.concat(this.newQuestions)

            // Verificando se ao menos uma questão está sendo cadastrada
            if (this.form.questions.length === 0) {
                endLoading('form', 'save');
                this.$notifyError('Insira ao menos uma questão!')
                this.hideSpinner();
                return;
            }

            // Inserindos dados no POST
            const data = toFormData(this.form);

            // Inserindo dados das questão no POST
            let missingInformation = false;
            this.form.questions.forEach((element, index) => {
                if (element.question === '' || element.type === '' || (element.type > 1 && element.alternatives.length === 0)) {
                    endLoading('form', 'save');
                    this.$notify({
                        title: `<i class="fa fa-times-circle mr-2 mt-2"></i> <span>A Questão ` + (index + 1) + ` está com informações faltando!</span>`,
                        type: 'error',
                        duration: 3000,
                        closeOnClick: true
                    });
                    this.hideSpinner();
                    missingInformation = true;
                }

                data.append(`question[${index}][question_text]`, element.question);
                data.append(`question[${index}][id]`, element.id);
                data.append(`type[${index}]`, element.type);
                if (element.alternatives.length > 0) data.append(`alternatives[${index}]`, JSON.stringify(element.alternatives));
            })

            if (missingInformation) return;

            let url = 'forms';
            // Se for edição enviar como método PUT
            if (this.form.id > 0) {
                url += `/${this.form.id}`;
                data.append('_method', 'PUT');
            }

            // Enviar dados ao back end
            http.post(url, data)
                .then(response => {
                    this.showSpinner = false;
                    this.$notifySuccess(response.data);
                    if (!this.form.id) {
                        this.$router.push('/formularios');
                    }
                })
                .catch(e => {
                    console.error('Mentoring Register: ', e.response.data);
                    this.$notifyError(e);
                })
                .finally(() => {
                    endLoading('form', 'save');
                    this.hideSpinner();
                })
        },

        // Buscar dados da mentoria
        getData(id) {
            http.get('forms/' + id)
                .then(response => {
                    this.form = response.data.data[0];

                    let mentorings = [];
                    // Pegando apenas os id's das mentorias
                    this.form.mentorings.forEach(element => {
                        mentorings.push(element.id)
                    })

                    this.form.mentorings = mentorings;
                })
                .catch(e => {
                    console.error(e);
                    this.$notifyError('Erro ao carregar dados do Formulário!');
                })
        },


        // Update em nova questão que foi editada antes do submit
        updateNewQuestion(payload) {
            this.newQuestions[payload.index] = payload.data;
        },

        hideSpinner() {
            setTimeout(() => {
                this.showSpinner = false;
            }, 300)
        },
    },

    mounted() {
        this.formId = this.$route.params.id
        if (this.formId) this.getData(this.formId);
        this.initializeEditors();
    }
}
</script>
